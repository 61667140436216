import React ,{useState ,useContext, useEffect, useRef } from 'react'
import { quizContext } from '../context';
import { useNavigate } from 'react-router-dom';
import arrow from "../assets/images/left_arrow.png";


function Questions() {
  const { quiz , setQuiz } = useContext(quizContext);
  const [selectedOption, setselectedOption] = useState(-1);
  const [questionsNumberVisible, setquestionsNumberVisible] = useState(true);
  const [countDownTimer, setcountDownTimer] = useState('-00:00:00');
  const [isConfirmBoxVisible, setisConfirmBoxVisible] = useState(false);
  // console.log(quiz);
  const navigate = useNavigate();
  const Ref = useRef(null);

  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / 1000 / 60 / 60) % 24);
    return {
        total, hours, minutes, seconds
    };
  }

  const startTimer = (e) => {
    let { total, hours, minutes, seconds }
                = getTimeRemaining(e);
    if (total >= 0) {
        setcountDownTimer(
            (hours > 9 ? hours : '0' + hours) + ':' +
            (minutes > 9 ? minutes : '0' + minutes) + ':'
            + (seconds > 9 ? seconds : '0' + seconds)
        )
    }
    else {
      if (Ref.current)clearInterval(Ref.current);
      navigate("/result");
    }
  }

  const clearTimer = (e) => {
      if (Ref.current) clearInterval(Ref.current);
      const id = setInterval(() => {
          startTimer(e);
      }, 1000)
      Ref.current = id;
  }

  const getDeadTime = () => {
      let deadline = new Date();
      let totaltime = quiz.TimePerQuestion ? quiz.TimePerQuestion : quiz.TotalTime
      deadline.setSeconds(deadline.getSeconds() + parseInt(totaltime)*60);
      return deadline;
  }

  useEffect(() => {
    if(quiz.onGoing === false){
      navigate("/");
    }
    clearTimer(getDeadTime());

    return ()=>{
      if (Ref.current){
        clearInterval(Ref.current);
     }
    }
  }, [])
  

  const nextQuestion = ()=>{
    if(quiz.currIndex+1 >= quiz.total){
      submitQuiz();
      return ;
    }
    setQuiz({...quiz , currIndex : quiz.currIndex+1});
    setselectedOption(quiz.list[quiz.currIndex+1].selectedOption);
  }

  const previousQuestion = ()=>{
    if(quiz.currIndex-1 < 0 ){
      return ;
    }
    setQuiz({...quiz , currIndex : quiz.currIndex-1});
    setselectedOption(quiz.list[quiz.currIndex-1].selectedOption);
  }

  const handleSelectedOption = (option)=>{
    if(option === selectedOption){
      console.log("Same Option Selected To remove That !");
      option = -1;
    }
    setselectedOption(option);
    console.log("Selected Option is" , option);
    quiz.list[quiz.currIndex].selectedOption = option;
    setQuiz({...quiz , list : [...quiz.list]});
  }

  const markForReview = (e)=>{
    console.log(e.target.checked);
    quiz.list[quiz.currIndex].markForReview = e.target.checked;
    setQuiz({...quiz , list : [...quiz.list]});
  }

  const submitQuiz = ()=>{
    if(countDownTimer !== "00:00:00"){
      setisConfirmBoxVisible(true);
      return ;
    }
    navigate("/result");
  }

  return (
    <>{ quiz.onGoing && <div className='flex justify-center items-start flex-wrap'>
      {isConfirmBoxVisible && <div className='go-screen flex flex-col justify-center items-center'>
        <h1 className='font-extrabold text-4xl text-white text-center px-2'>You Are Submitting The Quiz Before Time</h1>
        <div className='font-extrabold text-xl text-white text-center px-2 mt-3 space-x-2'><span>Are You Sure ?</span> <button className='bg-red-700 text-white px-5 py-1 rounded-lg shadow-box' onClick={()=>{
          navigate("/result");
          setisConfirmBoxVisible(false);
        }}>Yes</button>  
        <button className='bg-green-700 text-white px-5 py-1 rounded-lg shadow-box' onClick={()=>{
          setisConfirmBoxVisible(false);
        }}>No</button></div>
      </div>}
      <div className='w-full xl:w-1/4 lg:w-1/4 md:w-1/4 bg-gray-200 mt-5 py-5 px-2'>
        <h4 className='text-center font-semibold flex justify-center items-center cursor-pointer' onClick={()=>{setquestionsNumberVisible(!questionsNumberVisible)}}><span>Question Numbers</span> <span><img className={questionsNumberVisible ? "rotate-90" : "-rotate-90"} width={20} src={arrow} alt="arrow" /> </span></h4>
        { questionsNumberVisible && <div className='flex justify-between mt-3 flex-wrap'>
          <div className='flex text-xs justify-center items-center mt-1'>
            <div className='w-5 h-5 bg-orange-300 mr-2'></div><span> Not Attempted</span>
          </div>
          <div className='flex text-xs justify-center items-center mt-1'>
            <div className='w-5 h-5 bg-blue-300 mr-2'></div><span> Marked For Review</span>
          </div>
          <div className='flex text-xs justify-center items-center mt-1'>
            <div className='w-5 h-5 bg-green-300 mr-2 '></div><span> Attempted</span>
          </div>
        </div>}
        {questionsNumberVisible && <div className='grid grid-cols-6 lg:grid-cols-5 md:grid-cols-3 gap-2 mt-5' onClick={(e)=>{
            setQuiz({...quiz , currIndex : parseInt(e.target.innerText)-1});
            setselectedOption(quiz.list[parseInt(e.target.innerText)-1].selectedOption);
          }}>
          {
            quiz.list.map((item,id)=>{
              return (
                <div key={id} className={`${item.markForReview ? "bg-blue-300" : item.selectedOption!==-1 ? "bg-green-300":"bg-orange-300"} ${quiz.currIndex === id &&"border border-black shadow-box"} text-center p-3 cursor-pointer`} >{id+1}</div>
              )
            })
          }
        </div>}
      </div>
      <div className='px-5 w-full xl:w-3/4 lg:w-3/4 md:w-3/4'>
        <div className='flex justify-end my-5'>
          <div className='text-lg font-bold bg-green-700 text-white text-center py-2 rounded-lg px-2 mr-5 cursor-pointer' onClick={submitQuiz}>Submit Quiz</div>
          <div className='text-lg font-bold bg-red-400 text-white text-center py-2 rounded-lg w-[100px]'>{countDownTimer}</div>
        </div>
        <div className='mt-5 font-semibold text-lg'>
          <span>{quiz.currIndex+1}. </span>{quiz.list[quiz.currIndex].question.Title}
        </div>

        {quiz.list[quiz.currIndex].question.QuestionImgurl.toLowerCase() !=="null" &&<div>
          <img src={quiz.list[quiz.currIndex].question.QuestionImgurl} alt="Question" width={300}/>
        </div>}


        {<div className='mt-5 text-lg'>
          <span dangerouslySetInnerHTML={ {__html :quiz.list[quiz.currIndex].question.Description}}></span>
        </div>}

        <ul className='space-y-2 mt-5'>
          <li className='flex justify-start space-x-3' onClick={()=>{handleSelectedOption(1)}} ><input checked ={selectedOption === 1} type="radio" name="option" value={1} onChange={()=>{}}/> <span dangerouslySetInnerHTML={ {__html :quiz.list[quiz.currIndex].question.Option1}}></span> </li>
          <li className='flex justify-start space-x-3' onClick={()=>{handleSelectedOption(2)}} ><input checked ={selectedOption === 2} type="radio" name="option" value={2} onChange={()=>{}}/> <span dangerouslySetInnerHTML={ {__html :quiz.list[quiz.currIndex].question.Option2}}></span></li>
          <li className='flex justify-start space-x-3' onClick={()=>{handleSelectedOption(3)}} ><input checked ={selectedOption === 3} type="radio" name="option" value={3} onChange={()=>{}}/> <span dangerouslySetInnerHTML={ {__html :quiz.list[quiz.currIndex].question.Option3}}></span></li>
          <li className='flex justify-start space-x-3' onClick={()=>{handleSelectedOption(4)}} ><input checked ={selectedOption === 4} type="radio" name="option" value={4} onChange={()=>{}}/> <span dangerouslySetInnerHTML={ {__html :quiz.list[quiz.currIndex].question.Option4}}></span></li>
        </ul>

        <div className='mt-10 space-x-2'>
          <label htmlFor="markforreview" >Mark For Review</label>
          <input type="checkbox" name="markforreview" checked={quiz.list[quiz.currIndex].markForReview} onChange={(e)=>{markForReview(e)}}/>
        </div>

        <div className='flex justify-between items-center'>
          {  quiz.currIndex >0 && <button className='bg-orange-600 text-white mt-10 px-5 py-1 rounded-lg cursor-pointer' onClick={previousQuestion}> {"<-"} Previous </button>}
          <button className='bg-blue-600 text-white mt-10 px-5 py-1 rounded-lg cursor-pointer' onClick={nextQuestion}>{quiz.currIndex+1 !== quiz.total ? "Next": " Finish"}  {"->"}</button>
        </div>
      </div>
    </div>}</>
  )
}

export default Questions;