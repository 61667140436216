import React , {useContext} from 'react'
import logo from "../assets/images/logo.svg";
import profileImg from "../assets/images/profile_image.png";
// Routing Imports
import { useNavigate} from "react-router-dom";
import { userContext } from '../context';


function Navbar() {
  const navigate = useNavigate();
  const { user } = useContext(userContext);


  return (
    <div className={`flex ${ user ? "justify-between" : "justify-start"} items-center p-2 xl:p-5 lg:p-5 md:p-3`}>
        <div className='flex justify-center items-center space-x-2 cursor-pointer' onClick={()=>{
                    navigate("/")
            }}>
            <img src={logo} alt="logo" className='w-11 xl:w-20 lg:w-20 md:w-18 sm:w-11'/>
            <div className='font-extrabold text-[20px] xl:text-[30px] lg:text-[30px] md:text-[26px]'>Exams Wala</div>
        </div>
        {user && <img className='cursor-pointer w-11 xl:w-20 lg:w-20 md:w-18 sm:w-11' src={profileImg} onClick={()=>{
                    navigate("/profile")
            }}/>}
    </div>
  )
}

export default Navbar