import React from "react";

export const userContext = React.createContext({
    user : null,
    setUSer : (user) =>{}
})

export const userProfileContext = React.createContext({
    userProfile : {},
    setUserProfile : (user) =>{}
})

export const quizContext = React.createContext({
    quiz : "Value",
    setQuiz : (quiz) =>{}
})