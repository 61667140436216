import React ,{useState , useEffect} from 'react'
import { useParams } from 'react-router-dom';
//firebase imports
import { realDb } from "../firebase/firebaseConfig";
import { ref as dRef ,onValue , update} from "firebase/database";
import Loader from './Loader';
import Dropdown from './common/Dropdown';
import { getAllSubjectTags , getAllTopicTags } from '../utils/commonFunc';
import Editor from './common/Editor';

function EditQuestion() {
    const {id} = useParams();

    const [image, setImage] = useState(null);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [option1, setOption1] = useState("");
  const [option2, setOption2] = useState("");
  const [option3, setOption3] = useState("");
  const [option4, setOption4] = useState("");
  const [correctOption, setCorrectOption] = useState("");
  const [solution, setSolution] = useState("");
  const [isLoader, setisLoader] = useState(false);
  const [solutionImage, setSolutionImage] = useState(null);

  const [subjectTags, setSubjectTags] = useState([]);
  const [topicTags, setTopicTags] = useState([]);
  const [subjectTag, setSubjectTag] = useState('Select Subject Tag');
  const [topicTag, setTopicTag] = useState("Select Topic Tag");


  useEffect(() => {
    const path = dRef(realDb , `Questions/${id}`);
      onValue(path, (snapshot)=>{
        if(!snapshot.val())return ;
        console.log(snapshot.val());
        const question = snapshot.val();
        setTitle(question.Title);
        setDescription(question.Description);
        setImage(question.QuestionImgurl);
        setOption1(question.Option1);
        setOption2(question.Option2);
        setOption3(question.Option3);
        setOption4(question.Option4);
        setCorrectOption(question.CorrectOption);
        setSolution(question.Solution);
        setSolutionImage(question.SolutionImgUrl);
        setSubjectTag(question.subjectTag);
        setTopicTag(question.topicTag);
      })
  }, [id])


  useEffect(() => {
    getAllSubjectTags(setSubjectTags);
    getAllTopicTags(setTopicTags);
  }, [])


  const updateQuestion = ()=>{
    setTitle(title.trim());
    setDescription(description.trim());
    setOption1(option1.trim());
    setOption2(option2.trim());
    setOption3(option3.trim());
    setOption4(option4.trim());
    setCorrectOption(correctOption.trim());
    setSolution(solution.trim());


    if(!title || !option1 || !option2 || !option3 || !option4 || !correctOption){
      alert("Please Fill All Mandatory Fields 🙁");
      return ;
    }
    setisLoader(true);

    const dbref = dRef(realDb, `Questions/${id}`);
      const data = {
          "Title": title,
          "Description": description,
          "Option1": option1,
          "Option2": option2,
          "Option3": option3,
          "Option4": option4,
          "CorrectOption" : correctOption,
          "Solution" : solution,
          "QuestionImgurl": image,
          "SolutionImgUrl" : solutionImage,
          "subjectTag":subjectTag,
          "topicTag":topicTag
      }
      console.log(data);
      update(dbref, data);
      setisLoader(false);
      alert("Question Updated 👍");
  }
    
  return (
    <div className='px-5 space-y-5 mt-10'>
      {isLoader && <Loader text='Uploading ...'/>}
      <h5 className='text-lg font-semibold mb-10'>Update Question</h5>
      <div>
        <h3>Title*</h3>
        <input type="text" className='border border-gray-400 rounded-lg px-2 py-1 w-full' placeholder='which is best quiz web site ?' value={title} onChange={(e)=>{setTitle(e.target.value)}}/>
      </div>

      <div>
        <h3>Description</h3>
        {/* <textarea type="text" className='border border-gray-400 rounded-lg px-2 py-1 w-full' placeholder='Description of Question' value={description} onChange={(e)=>{setDescription(e.target.value)}}/> */}
        <Editor value={description} setValue={setDescription} placeholder='Description of Question'/>
      </div>

      <div>
        <h3>Image</h3>
        <input  type="text" className='border border-gray-400 rounded-lg px-2 py-1 w-full' placeholder='Description of Question' value={image} onChange={(e)=>{setImage(e.target.value)}}/>
      </div>

      {
        image && image.toLocaleLowerCase() !== "null" && 
        <div>
          <img  src={image} alt="preview"  />
        </div>
      }

      <div>
        <h3>Option 1*</h3>
        {/* <input type="text" className='border border-gray-400 rounded-lg px-2 py-1 w-full' placeholder='Exams Wala' value={option1} onChange={(e)=>{setOption1(e.target.value)}}/> */}
        <Editor value={option1} setValue={setOption1}/>
      </div>

      <div>
        <h3>Option 2*</h3>
        {/* <input type="text" className='border border-gray-400 rounded-lg px-2 py-1 w-full' placeholder='Exams Wala' value={option2} onChange={(e)=>{setOption2(e.target.value)}}/> */}
        <Editor value={option2} setValue={setOption2}/>
      </div>

      <div>
        <h3>Option 3*</h3>
        {/* <input type="text" className='border border-gray-400 rounded-lg px-2 py-1 w-full' placeholder='Exams Wala' value={option3} onChange={(e)=>{setOption3(e.target.value)}}/> */}
        <Editor value={option3} setValue={setOption3}/>
      </div>

      <div>
        <h3>Option 4*</h3>
        {/* <input type="text" className='border border-gray-400 rounded-lg px-2 py-1 w-full' placeholder='Exams Wala' value={option4} onChange={(e)=>{setOption4(e.target.value)}}/> */}
        <Editor value={option4} setValue={setOption4}/>
      </div>

      <div>
        <h3>Correct Option*</h3>
        <input type="number" className='border border-gray-400 rounded-lg px-2 py-1 w-full' placeholder='1' value={correctOption} onChange={(e)=>{setCorrectOption(e.target.value)}}/>
      </div>

      <div>
        <h3>Solution</h3>
        {/* <textarea type="text" className='border border-gray-400 rounded-lg px-2 py-1 w-full' placeholder='Explanation of Question' value={solution} onChange={(e)=>{setSolution(e.target.value)}}/> */}
        <Editor value={solution} setValue={setSolution}/>
      </div>

      <div>
        <h3>Solution Image</h3>
        <input  type="text" className='border border-gray-400 rounded-lg px-2 py-1 w-full' placeholder='Description of Question' value={solutionImage} onChange={(e)=>{setSolutionImage(e.target.value)}}/>
      </div>

      {
        solutionImage && solutionImage.toLocaleLowerCase() !== "null" && 
        <div>
          <img  src={solutionImage} alt="preview"  />
        </div>
      }

      <div>
        <h3>Subject Tag*</h3>
        <Dropdown list={subjectTags} selected={subjectTag} setSelected={setSubjectTag} title='Select Subject Tag'/>
      </div>

      <div>
        <h3>Topic Tag*</h3>
        <Dropdown list={topicTags} selected={topicTag} setSelected={setTopicTag} title='Select Topic Tag'/>
      </div>


      <div className='w-[300px]'>
          <button className='bg-blue-500 text-white px-5 py-1 rounded-xl' onClick={updateQuestion}>Update Question</button>
      </div>
    </div>
  )
}

export default EditQuestion;