import React, { useState } from 'react'
import signup from "../assets/images/register.jpg";

import { validateEmail } from '../utils/commonFunc';
import Loader from './Loader';

// Routing Imports
import { useNavigate} from "react-router-dom";

//firebase imports
import { auth, realDb } from "../firebase/firebaseConfig";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { set, ref } from "firebase/database";

function SignUp() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isLoader, setisLoader] = useState(false);

  const createNewAccount= ()=>{
    setEmail(email.trim());
    setPassword(password.trim());
    setConfirmPassword(confirmPassword.trim());

    if(email.length === 0 || password.length === 0 || confirmPassword.length === 0){
        alert("Please Fill All Fields 🙁");
        return;
    }
    if(!validateEmail(email)){
        alert("Email is not  Correct 🙁");
        return ;
    }
    if(password !== confirmPassword){
        alert("Password Dosn't Match 🙁");
        return;
    }
    setisLoader(true);
    createUserWithEmailAndPassword(auth, email, password).then(newuser => {
        let name = email.split("@")[0];
        let profile = {
            email: email,
            userName : name,
            Name : "undefined",
            tagLine : "I am Student 😍",
            RankBasedOnSolvedQuizes : "",
            solvedQuizes :0,
            attemptedQuizList : [],
            maxStreak : 0,
            currentStreak : 0,
            totalActiveDays :0,
            Location : "undefined",
            subjectList : [],
            topicList : [],
            instagram:"",
            facebook:"",
            youtube:"",
            lastActiveDate:""
        }
        let path = ref(realDb, 'users/' + name)
        set(path, profile);
        setEmail("");
        setPassword("");
        setConfirmPassword("");
        setisLoader(false);
        alert("Account Created 🥳");  
        navigate("/profile/edit")   
    }).catch(err => {
        setisLoader(false);
        alert(err + "😣");
    })
  }


  return (
    <div className='flex justify-center items-center space-x-5 flex-wrap'>
        {isLoader && <Loader text='Uploading ...'/>}
        <div className='w-full xl:w-1/2 lg:w-1/2 md:w-1/2'>
            <img src={signup} alt="signup"  />
        </div>
        <div className='w-full xl:w-1/3 lg:w-1/3 md:w-1/3 space-y-4 flex justify-center items-center flex-col'>
            <div>
                <h3>Email Address*</h3>
                <input type="email" className='border border-gray-400 rounded-lg px-2 py-1 w-[300px]' placeholder='Enter Email' value={email} onChange={(e)=>{setEmail(e.target.value)}}/>
                <p className='text-xs text-gray-700'>We'll never share your email with anyone else.</p>
            </div>

            <div>
                <h3>Password*</h3>
                <input type="password" className='border border-gray-400 rounded-lg px-2 py-1 w-[300px]' placeholder='Enter Password' value={password} onChange={(e)=>{setPassword(e.target.value)}}/>
            </div>

            <div>
                <h3>Confirm Password*</h3>
                <input type="password" className='border border-gray-400 rounded-lg px-2 py-1 w-[300px]' placeholder='Confirm Password' value={confirmPassword} onChange={(e)=>{setConfirmPassword(e.target.value)}}/>
            </div>

            <div className='w-[300px]'>
                <button className='bg-blue-500 text-white px-5 py-1 rounded-xl' onClick={createNewAccount}>Sign Up</button>
                <div className='mt-3 text-left pl-2 text-sm text-gray-700' onClick={()=>{
                    navigate("/signin")
                }}>
                    Already Have an Acount ? <span className='text-blue-900 font-semibold cursor-pointer'>Log IN</span>
                </div>
            </div>
        </div>
    </div>
  )
}

export default SignUp;