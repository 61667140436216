import React ,{useEffect , useState} from 'react'
import { storage } from '../firebase/firebaseConfig';
import {  ref, listAll , getDownloadURL ,uploadBytesResumable } from "firebase/storage";
import copy from "copy-to-clipboard";
import Loader from './Loader';



function ImageGallery() {
    const [imagesList, setImagesList] = useState([]);
    const [image, setImage] = useState(null);
    const [imagePreview, setImagePreview] = useState();
    const [isLoader, setisLoader] = useState(true);

    const onSelectFile = e => {
        if (!e.target.files || e.target.files.length === 0) {
            setImage(undefined)
            return
        }
        setImage(e.target.files[0])
    }

    useEffect(() => {
      if (!image) {
          setImagePreview(undefined)
          return
      }
      const objectUrl = URL.createObjectURL(image)
      setImagePreview(objectUrl)
      return () => URL.revokeObjectURL(objectUrl)
    }, [image])

    const addNewImage = ()=>{
      if(!image)return;
      const path = ref(storage, `images/${image.name + new Date().getMilliseconds()}`);
      const uploadTask = uploadBytesResumable(path, image);

      uploadTask.on("state_changed", (snapshot) => {
          const prog = Math.round(snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log(prog);
      }, (err) => {
          console.log(err);
          alert(err);
      }, () => {
          getDownloadURL(uploadTask.snapshot.ref).then(Url => {
            alert("Image Uploaded ✅");
          });
      })
    }



    useEffect(() => {
        const listRef = ref(storage, 'images/');

        // Find all the prefixes and items.
        listAll(listRef)
          .then((res) => {
            let promises = res.items.map((imageRef) => getDownloadURL(imageRef));
            Promise.all(promises).then((urls) => {
                console.log(urls);
                setImagesList(urls);
                setisLoader(false)
            })
          }).catch((error) => {
            console.log("Nhi Aya yrr");
            setisLoader(false);
          });
    }, [])
    
  return (
    <div>
      {isLoader && <Loader text='Uploading ...'/>}
        <h3 className='text-center font-bold my-5'>Image Gallery</h3>
        <div className='px-5'>
          <div>
            <input accept="image/*" type="file" className='border border-gray-400 rounded-lg px-2 py-1 w-full' placeholder='Description of Question' onChange={onSelectFile}/>
          </div>

          {
            image && 
            <div>
              <img  src={imagePreview} alt="preview"  />
            </div>
          }
          <button className='bg-blue-500 text-white px-5 py-1 rounded-lg mb-5 mt-2' onClick={addNewImage}>Add New Image</button>
        </div>
        <div className='grid grid-cols-1 xl:grid-cols-4 lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 gap-5 px-5 mt-3'>
            {imagesList.map((item,id)=>{
                return (
                    <div className='rounded-lg shadow-box relative' >
                        <div className='absolute top-2 right-2 shadow-box px-2 rounded-xl bg-white cursor-pointer' onClick={()=>{
                            copy(item)
                            alert("Image Url is Copied on Clipboard . Now You can Paste it 🎉")
                        }}>Copy</div>
                        <img className='rounded-lg' width={500} height={500} src={item} alt="preview"  />
                    </div>
                )
            })}
        </div>
    </div>
  )
}

export default ImageGallery;