import React, { useState } from 'react'
import login from "../assets/images/signin.jpg";
// Routing Imports
import { useNavigate} from "react-router-dom";

import { validateEmail,  sendPasswordResetLink} from '../utils/commonFunc';
import Loader from './Loader';

//firebase imports
import { auth} from "../firebase/firebaseConfig";
import { signInWithEmailAndPassword } from "firebase/auth";

function LogIn() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoader, setisLoader] = useState(false);

  const logIn = ()=>{
    setEmail(email.trim());
    setPassword(password.trim());

    if(email.length === 0 || password.length === 0){
        alert("Please Fill All Fields 🙁");
        return;
    }
    if(!validateEmail(email)){
        alert("Email is not  Correct 🙁");
        return ;
    }
    setisLoader(true);
    signInWithEmailAndPassword(auth, email, password).then(user => {
        user = auth.currentUser;
        setisLoader(false);
        alert("Logged In 🤩");
        navigate("/")        
    }).catch(err => {
        setisLoader(false);
        alert(err + "😣");
    })

  }


  return (
    <div className='flex justify-center items-center space-x-5 flex-wrap'>
        {isLoader && <Loader text='Uploading ...'/>}
        <div className='w-full xl:w-1/2 lg:w-1/2 md:w-1/2'>
            <img src={login} alt="login"  />
        </div>
        <div className='w-full xl:w-1/3 lg:w-1/3 md:w-1/3 space-y-4 flex justify-center items-center flex-col'>
            <div>
                <h3>Email Address</h3>
                <input type="text" className='border border-gray-400 rounded-lg px-2 py-1 w-[300px]' placeholder='Enter Email' value={email} onChange={(e)=>{setEmail(e.target.value)}}/>
                <p className='text-xs text-gray-700'>We'll never share your email with anyone</p>
            </div>

            <div>
                <h3>Password</h3>
                <input type="password" className='border border-gray-400 rounded-lg px-2 py-1 w-[300px]' placeholder='Enter Password'value={password} onChange={(e)=>{setPassword(e.target.value)}}/>
                <p className='text-blue-900 text-xs text-right mt-2 cursor-pointer' onClick={()=>{sendPasswordResetLink(email)}}>Forget Password ?</p>
            </div>

            <div className='w-[300px]'>
                <button className='bg-blue-500 text-white px-5 py-1 rounded-xl' onClick={logIn}>Log In</button>
                <div className='mt-3 text-left pl-2 text-sm text-gray-700' onClick={()=>{
                    navigate("/signup")
                }}>
                    Dont Have an Acount ? <span className='text-blue-900 font-semibold cursor-pointer'>Sign Up</span>
                </div>
            </div>
        </div>
    </div>
  )
}

export default LogIn;