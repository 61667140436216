import React, {useRef} from 'react';
import JoditEditor from 'jodit-react';

const Editor = ({value , setValue}) => {
	const editor = useRef(null);

	return (
		<JoditEditor
			ref={editor}
			value={value}
			onChange={newContent => {setValue(newContent)}}
		/>
	);
};

export default Editor;