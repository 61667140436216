import React, {useState , useEffect} from 'react'
//firebase imports
import { storage } from "../firebase/firebaseConfig";
import { getDownloadURL , ref as sRef , uploadBytesResumable } from 'firebase/storage';
import Loader from './Loader';
import Dropdown from './common/Dropdown';
import { getAllSubjectTags , getAllTopicTags , saveInRealDB , onSelectImageFile } from '../utils/commonFunc';
import Editor from './common/Editor';

function CreateQues() {
  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [option1, setOption1] = useState("");
  const [option2, setOption2] = useState("");
  const [option3, setOption3] = useState("");
  const [option4, setOption4] = useState("");
  const [correctOption, setCorrectOption] = useState("");
  const [solution, setSolution] = useState("");
  const [isLoader, setisLoader] = useState(false);
  const [solutionImage, setSolutionImage] = useState(null);
  const [solutionImagePreview, setSolutionImagePreview] = useState();

  const [subjectTags, setSubjectTags] = useState([]);
  const [topicTags, setTopicTags] = useState([]);
  const [subjectTag, setSubjectTag] = useState('Select Subject Tag');
  const [topicTag, setTopicTag] = useState("Select Topic Tag");

  useEffect(() => {
    getAllSubjectTags(setSubjectTags);
    getAllTopicTags(setTopicTags);
  }, [])
  

    useEffect(() => {
      if (!image) {
          setImagePreview(undefined)
          return
      }
      const objectUrl = URL.createObjectURL(image)
      setImagePreview(objectUrl)
      return () => URL.revokeObjectURL(objectUrl)
  }, [image ])

    useEffect(() => {
      if (!solutionImage) {
          setSolutionImagePreview(undefined)
          return
      }
      const objectUrl = URL.createObjectURL(solutionImage)
      setSolutionImagePreview(objectUrl)
      return () => URL.revokeObjectURL(objectUrl)
  }, [solutionImage])


    const storeInRealDB = (questionImageUrl , solutionImageUrl)=>{
      const data = {
          "Title": title,
          "Description": description,
          "Option1": option1,
          "Option2": option2,
          "Option3": option3,
          "Option4": option4,
          "CorrectOption" : correctOption,
          "Solution" : solution,
          "QuestionImgurl": questionImageUrl ? questionImageUrl : "null",
          "SolutionImgUrl" : solutionImageUrl ? solutionImageUrl : "null",
          "subjectTag":subjectTag,
          "topicTag":topicTag
      }
      console.log(data);
      saveInRealDB("Questions/" , data);
      setisLoader(false);
      alert("Question Created 👍")
      setTitle("");
      setDescription("");
      setOption1("");
      setOption2("");
      setOption3("");
      setOption4("");
      setCorrectOption("");
      setSolution("");
      setImage(null);
      setImagePreview(null);
      setSolutionImage(null);
      setSolutionImagePreview(null);
      setSubjectTag('Select Subject Tag')
      setTopicTag("Select Topic Tag")
    }


    const createQuestion = async()=>{
      setTitle(title.trim());
      setDescription(description.trim());
      setOption1(option1.trim());
      setOption2(option2.trim());
      setOption3(option3.trim());
      setOption4(option4.trim());
      setCorrectOption(correctOption.trim());
      setSolution(solution.trim());


      if(!title || !option1 || !option2 || !option3 || !option4 || !correctOption || subjectTag === "Select Subject Tag" || topicTag === "Select Topic Tag"){
        alert("Please Fill All Mandatory Fields 🙁");
        return ;
      }
      setisLoader(true);

      let questionImageUrl = null ;
      let solutionImageUrl = null ;

      if(image){
        try{
          const path = sRef(storage, `images/${image.name + new Date().getMilliseconds()}`);
          await uploadBytesResumable(path, image);
          questionImageUrl = await getDownloadURL(path);
        }
        catch (err){
          alert(err);
          setisLoader(false);
        }
      }

      if(solutionImage){
        try { const path = sRef(storage, `images/${solutionImage.name + new Date().getMilliseconds()}`);
          await uploadBytesResumable(path, solutionImage);
          solutionImageUrl = await getDownloadURL(path);
        }
        catch (err){
          alert(err);
          setisLoader(false);
        }
      }
      storeInRealDB(questionImageUrl,solutionImageUrl);
    }

  return (
    <div className='px-5 space-y-5 mt-10'>
      {isLoader && <Loader text='Uploading ...'/>}
      <h5 className='text-lg font-semibold mb-10'>Create New Question</h5>
      <div>
        <h3>Title*</h3>
        <input type="text" className='border border-gray-400 rounded-lg px-2 py-1 w-full' placeholder='which is best quiz web site ?' value={title} onChange={(e)=>{setTitle(e.target.value)}}/>
      </div>

      <div>
        <h3 className='font-bold mb-3'>Description</h3>
        {/* <textarea type="text" className='border border-gray-400 rounded-lg px-2 py-1 w-full' placeholder='Description of Question' value={description} onChange={(e)=>{setDescription(e.target.value)}}/> */}
        <Editor value={description} setValue={setDescription} placeholder='Description of Question'/>
        {/* {description} */}
      </div>

      <div>
        <h3>Image</h3>
        <input accept="image/*" type="file" className='border border-gray-400 rounded-lg px-2 py-1 w-full' placeholder='Description of Question' onChange={(e)=>{onSelectImageFile(e,setImage)}}/>
      </div>

      {
        image && 
        <div>
          <img  src={imagePreview} alt="preview"  />
        </div>
      }

      <div>
        <h3 className='font-bold mb-3'>Option 1*</h3>
        {/* <input type="text" className='border border-gray-400 rounded-lg px-2 py-1 w-full' placeholder='Exams Wala' value={option1} onChange={(e)=>{setOption1(e.target.value)}}/> */}
        <Editor value={option1} setValue={setOption1}/>
        {/* {option1} */}
      </div>

      <div>
        <h3 className='font-bold mb-3'>Option 2*</h3>
        {/* <input type="text" className='border border-gray-400 rounded-lg px-2 py-1 w-full' placeholder='Exams Wala' value={option2} onChange={(e)=>{setOption2(e.target.value)}}/> */}
        <Editor value={option2} setValue={setOption2}/>
      </div>

      <div>
        <h3 className='font-bold mb-3'>Option 3*</h3>
        {/* <input type="text" className='border border-gray-400 rounded-lg px-2 py-1 w-full' placeholder='Exams Wala' value={option3} onChange={(e)=>{setOption3(e.target.value)}}/> */}
        <Editor value={option3} setValue={setOption3}/>
      </div>

      <div>
        <h3 className='font-bold mb-3'>Option 4*</h3>
        {/* <input type="text" className='border border-gray-400 rounded-lg px-2 py-1 w-full' placeholder='Exams Wala' value={option4} onChange={(e)=>{setOption4(e.target.value)}}/> */}
        <Editor value={option4} setValue={setOption4}/>
      </div>

      <div>
        <h3>Correct Option*</h3>
        <input type="number" className='border border-gray-400 rounded-lg px-2 py-1 w-full' placeholder='1' value={correctOption} onChange={(e)=>{setCorrectOption(e.target.value)}}/>
      </div>

      <div>
        <h3 className='font-bold mb-3'>Solution</h3>
        {/* <textarea type="text" className='border border-gray-400 rounded-lg px-2 py-1 w-full' placeholder='Explanation of Question' value={solution} onChange={(e)=>{setSolution(e.target.value)}}/> */}
        <Editor value={solution} setValue={setSolution}/>
      </div>

      <div>
        <h3>Solution Image</h3>
        <input accept="image/*" type="file" className='border border-gray-400 rounded-lg px-2 py-1 w-full' placeholder='Description of Question' onChange={(e)=>{onSelectImageFile(e,setSolutionImage)}}/>
      </div>

      {
        solutionImage && 
        <div>
          <img  src={solutionImagePreview} alt="preview"  />
        </div>
      }

      <div>
        <h3>Subject Tag*</h3>
        <Dropdown list={subjectTags} selected={subjectTag} setSelected={setSubjectTag} title='Select Subject Tag'/>
      </div>

      <div>
        <h3>Topic Tag*</h3>
        <Dropdown list={topicTags} selected={topicTag} setSelected={setTopicTag} title='Select Topic Tag'/>
      </div>


      <div className='w-[300px]'>
          <button className='bg-blue-500 text-white px-5 py-1 rounded-xl' onClick={createQuestion}>Create Question</button>
      </div>
    </div>
  )
}

export default CreateQues;