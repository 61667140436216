import React ,{useState , useEffect , useContext} from 'react'
import { useParams } from 'react-router-dom';
import Loader from './Loader';
import { realDb } from "../firebase/firebaseConfig";
import { ref as dRef ,onValue , child , get , getDatabase} from "firebase/database";
import go from "../assets/images/go.svg";
import { quizContext } from '../context';
import { useNavigate } from 'react-router-dom';


function Quiz() {
  const [isLoader, setisLoader] = useState(true);
  const [quizDetails, setquizDetails] = useState(null);
  const [goTimer, setgoTimer] = useState(5);
  const [isGoTimerVisible, setisGoTimerVisible] = useState(false);
  const {id} = useParams();
  const { setQuiz } = useContext(quizContext);
  // console.log(quiz);
  const naviate = useNavigate();

  useEffect(() => {
    const path = dRef(realDb , `Quizes/${id}`);
      onValue(path, (snapshot)=>{
        const data = snapshot.val();
        if(!data)return ;
        // console.log(data);
        setquizDetails(data);
        setisLoader(false);
      })
  }, [id])

  const startGoTimer = ()=>{
    setisGoTimerVisible(true);
    let timer = 5;
    const internval = setInterval(() => {
      setgoTimer(timer);
      timer = timer-1;
      if(timer<-1){
        clearInterval(internval);
        setisGoTimerVisible(false);
        setgoTimer(5);
        naviate("/questions");
      }
    }, 1000);
    setUpQuiz();
  }

  const setUpQuiz = async ()=>{
    const questions = [];
    for(let id in quizDetails.QuestionList){
      console.log(quizDetails.QuestionList[id]);
      const data =await get(child(dRef(getDatabase()), `Questions/${quizDetails.QuestionList[id]}`))
      if(data.exists()){
        questions.push({"question":data.val() , "selectedOption":-1 , "markedForReview" : false});
      }
    }
    setQuiz({...quizDetails , "list" : questions , "currIndex" : 0 , "total":questions.length , "onGoing" : true , id});
  }

  return (
    <div>
      {isLoader && <Loader text='Uploading ...'/>}
      {isGoTimerVisible &&
        <div className='go-screen flex flex-col justify-center items-center'>
          <img src={go} alt="go" />
          <div className='text-white font-extrabold text-8xl'>{goTimer}</div>
        </div>
      }
      {quizDetails && 
      <div className='mt-5 px-5'>
        <h3 className='text-center text-lg font-bold'>{quizDetails.Title}</h3>
        <div className='text-md font-normal mt-2'>{quizDetails.Description}</div>
        <div className='mt-2'> <span className='font-semibold text-orange-500'>Markes Per Question</span> :- {quizDetails.MarkesPerQuestion}</div>
        <div className='mt-2'> <span className='font-semibold text-orange-500'>Minus Marking</span> :- {quizDetails.MinusMarking}</div>
        <div className='mt-2'> <span className='font-semibold text-orange-500'>Total Time</span> :- {quizDetails.TimePerQuestion ? quizDetails.TimePerQuestion:quizDetails.TotalTime} min</div>
        <button className='mt-5 bg-red-500 text-white px-5 py-2 rounded-xl font-bold' onClick={startGoTimer}>Start Quiz</button>
      </div>}
    </div>
  )
}

export default Quiz;