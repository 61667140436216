import React from 'react'

function Footer() {
  return (
    <div className='Footer'>
        <h6>This site is designed & developed by Mr. Aryan Gupta.</h6>
        <h6>&copy; Copyright 2023 Exams Wala . All Rights Reserved.</h6>
    </div>
  )
}

export default Footer;