import React from 'react'

function Loader({
    text = "Loading ..."
}) {
  return (
    <div className="loading">Loading&#8230;</div>
  )
}

export default Loader;