import React , {useContext , useEffect} from 'react'
import { quizContext } from '../context';
import { useNavigate } from 'react-router-dom';


const QuestionCard = ({item ,id})=>{  
    
    return (
      <div className='shadow-box rounded-2xl items-center p-5 cursor-pointer relative'>
        <div>
          <div>
          {id+1}. <span className='font-semibold'>{item.question.Title}</span>
          </div>
          { item.question.Description && 
            <div className= "mt-2">
              Description :-  <p dangerouslySetInnerHTML={ {__html : item.question.Description}}></p>
            </div>
          }
        </div>
        <div className='space-y-2 mt-5'>
        {item.question.QuestionImgurl.toLowerCase() !=="null" ? <img src={item.question.QuestionImgurl} alt="question" width={500}/> : null}
        <div className='text-sm'>
            Option 1. <p dangerouslySetInnerHTML={ {__html : item.question.Option1}}></p>
        </div>
        <div className='text-sm'>
            Option 2. <p dangerouslySetInnerHTML={ {__html : item.question.Option2}}></p>
        </div>
        <div className='text-sm'>
            Option 3. <p dangerouslySetInnerHTML={ {__html : item.question.Option3}}></p>
        </div>
        <div className='text-sm'>
            Option 4. <p dangerouslySetInnerHTML={ {__html : item.question.Option4}}></p>
        </div>
        <div className='font-semibold text-sm text-green-600'>
            Correct. {item.question.CorrectOption} rth Option ✅.
        </div>
        {item.selectedOption > -1 ?<div className='font-semibold text-sm text-orange-600'>
            Selected. {item.selectedOption} rth Option {item.question.CorrectOption === item.selectedOption ? "✅" : "❌"}.
        </div> : <div className='font-semibold text-sm text-red-600'>Not Attempted !</div>}
        {item.markForReview && <div className='font-semibold text-sm text-blue-600'>Marked For Review</div>}
        <div>
            Solution. {item.question.Solution? <p dangerouslySetInnerHTML={ {__html : item.question.Solution}}></p> : "Solution Not Given ! 🥸"}
        </div>
        {item.question.SolutionImgUrl.toLowerCase() !=="null" ? <img src={item.question.SolutionImgUrl} width={500} alt="question" /> : null}
        </div>
  
      </div>
    )
  }
  
  

function Solution() {
  const { quiz } = useContext(quizContext);
  console.log(quiz);
  const navigate = useNavigate();

  useEffect(() => {
    if(!quiz.list){
        navigate("/")
    }
  }, [quiz])
  

  return (
    <div className='px-5 mt-5'>
        <div className='text-center text-lg font-semibold'>{quiz.Title}</div>
        <div className='mt-5 space-y-5'>
            {
                quiz.list && quiz.list.map((item,id)=>{
                    return (
                        <QuestionCard key={id} item={item} id={id} />
                    )
                })
            }
        </div>
    </div>
  )
}

export default Solution;