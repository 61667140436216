import React ,{useContext , useState , useEffect} from 'react'

import { userContext } from '../context';
import {  getUserProfile , updateInRealDB} from '../utils/commonFunc';
import { useNavigate } from 'react-router-dom';



function EditProfile() {
  const [profile, setProfile] = useState(null)
  const { user } = useContext(userContext);
  const navigate = useNavigate();


  useEffect(() => {
    getUserProfile(setProfile);
  }, [user])

  const updateProfile = ()=>{
    let ednPoint = `users/${user.email.split('@')[0]}`;
    updateInRealDB(ednPoint , profile);
    navigate("/profile");
  }

  return (
    <>{ profile && <div className='px-5 space-y-5 mt-10'>
      <h5 className='text-lg font-semibold mb-10'>Update Profile</h5>
      <div>
        <h3>Name</h3>
        <input type="text" className='border border-gray-400 rounded-lg px-2 py-1 w-full' placeholder='Aryan Gupta' value={profile.Name} onChange={(e)=>{
            setProfile({...profile , Name : e.target.value});
        }}/>
      </div>

      <div>
        <h3>Tag Line ( in short )</h3>
        <input type="text" className='border border-gray-400 rounded-lg px-2 py-1 w-full' placeholder='software developer' value={profile.tagLine} onChange={(e)=>{
            setProfile({...profile , tagLine : e.target.value});
        }}/>
      </div>

      <div>
        <h3>Location</h3>
        <input type="text" className='border border-gray-400 rounded-lg px-2 py-1 w-full' placeholder='india' value={profile.Location} onChange={(e)=>{
            setProfile({...profile , Location : e.target.value});
        }}/>
      </div>

      <div>
        <h3>Instagram</h3>
        <input type="text" className='border border-gray-400 rounded-lg px-2 py-1 w-full' placeholder='https://' value={profile.instagram} onChange={(e)=>{
            setProfile({...profile , instagram : e.target.value});
        }}/>
      </div>

      <div>
        <h3>Facebook</h3>
        <input type="text" className='border border-gray-400 rounded-lg px-2 py-1 w-full' placeholder='https://' value={profile.facebook} onChange={(e)=>{
            setProfile({...profile , facebook : e.target.value});
        }}/>
      </div>

      <div>
        <h3>YouTube</h3>
        <input type="text" className='border border-gray-400 rounded-lg px-2 py-1 w-full' placeholder='https://' value={profile.youtube} onChange={(e)=>{
            setProfile({...profile , youtube : e.target.value});
        }}/>
      </div>

      <div className='w-[300px]'>
          <button className='bg-blue-500 text-white px-5 py-1 rounded-xl' onClick={updateProfile}>Update Profile</button>
      </div>
    </div>}</>
  )
}

export default EditProfile;