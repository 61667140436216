import React from 'react'

function LeaderBoard() {
  return (
    <div className='flex justify-center items-center min-h-screen text-2xl text-orange-700 px-5'>
        There is No Contest Yet . After The Contest Board Will Be Available Here .
    </div>
  )
}

export default LeaderBoard;