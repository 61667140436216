import React ,{useContext , useState , useEffect} from 'react'
import profileImg from "../assets/images/profile_image.png"
import email from "../assets/images/email.png";
import location from "../assets/images/location.png";
import instagram from "../assets/images/Instagram.svg";
import fb from "../assets/images/fb.svg";
import youtube from "../assets/images/YouTube.svg";
import copy from "copy-to-clipboard";


// Routing Imports
import { useParams} from "react-router-dom";
import { userContext } from '../context';
import { signOut } from 'firebase/auth';
import { auth } from '../firebase/firebaseConfig';
import { useNavigate } from 'react-router-dom';
import { sendVerificationLink , getUserProfile ,reverseArr} from '../utils/commonFunc';

function Profile() {
  const [profile, setProfile] = useState(null)
  const { user , setUser } = useContext(userContext);
  const { uid } = useParams();
  const navigate = useNavigate();

  console.log(uid);

  useEffect(() => {
    if(uid){
      getUserProfile(setProfile,uid);
    }
    else{
      getUserProfile(setProfile);
    }
  }, [user,uid])
  
  console.log(profile);

  const logOut = ()=>{
    setUser(null);
    signOut(auth);
  }

  return ( <>
    { profile && <div className='flex justify-center items-start mt-10 flex-wrap xl:px-40 lg:px-36 md:px-5'>
      
      <div className='w-full xl:w-1/4 mb-10 px-5 xl:px-0 lg:px-0 md:px-0 '>
        <div className='flex justify-center items-center flex-col  shadow-box py-5 rounded-lg'>
          <img src={profileImg} alt="profile" className='w-32 xl:w-40 lg:w-40 md:w-36 sm:w-32' />
          <div className='mt-5 font-bold text-lg'>Name :- {profile.Name}</div>
          <div className='mt-2 font-bold text-lg text-orange-500'>{profile.userName}</div>
          { profile.tagLine && <div className='mt-2'>{profile.tagLine}</div>}
          { profile.RankBasedOnSolvedQuizes && <div className='mt-2'>Rank :- {profile.RankBasedOnSolvedQuizes}</div>}
          <div className='w-full px-5 mt-5 space-y-3'>
            {
              [
                {img:email , width : "20" , value : profile.email , className :"" , isLink : false},
                {img:location , width : "15" , value : profile.Location , className :"" , isLink : false},
                {img:instagram , width : "20" , value : profile.instagram , className :"text-xs cursor-pointer" , isLink : true},
                {img:fb , width : "20" , value : profile.facebook , className :"text-xs cursor-pointer" , isLink : true},
                {img:youtube , width : "20" , value : profile.youtube , className :"text-xs cursor-pointer" , isLink : true},
              ].map((item,id)=>{
                return (
                  <div key={id} className={`flex justify-start items-center space-x-3 ${item.value ? "":"hidden"}`}>
                      <img src={item.img} alt="icon" width={item.width} />
                      {item.isLink ? <a className={item.className} href={item.value} target='_blank' rel="noreferrer">{item.value}</a> : <div className={item.className}>{item.value}</div>}
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>

      <div className='w-full xl:w-3/4 px-5'>
        {!uid && <div className='flex justify-end mb-3'>
          <a href={`/profile/${profile.email.split('@')[0]}`} target='_blank' rel="noreferrer" className='bg-blue-400 px-3 rounded-xl text-white'>View Public Profile</a> <span className='ml-5 cursor-pointer underline' onClick={()=>{copy(`https://exams-wala.web.app/profile/${profile.email.split('@')[0]}`) ; alert("Your Profile Likn is Copied on Clipboard . Now You Can Share it With Your Friends 🎉")}}>Share</span>
        </div>}
        {!uid && auth.currentUser && !auth.currentUser.emailVerified && <h2 className='text-center my-5'>Email is not verified. <span className='text-orange-600 cursor-pointer ml-3 underline' onClick={sendVerificationLink}>Send Verification Link</span></h2>}

        <div className='grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-2 gap-5'>
          {
            [
              {title : "Solved Quizes" , tColor : "text-purple-700" , value : profile.solvedQuizes},
              {title : "Average Markes" , tColor : "text-yellow-700" , value :  profile.avgMarks?profile.avgMarks.toFixed(2) : "No quiz attempted yet" },
              {title : "Max Streak" , tColor : "text-red-700" , value : profile.maxStreak},
              {title : "Total Active Days" , tColor : "text-green-700" , value : profile.totalActiveDays},
              {title : "Current Streak" , tColor : "text-blue-700" , value : profile.currentStreak},
            ].map((item,id)=>{
              return (
                <div key={id} className='text-center shadow-box rounded-xl py-10'>
                  <h4 className={`font-semibold text-lg ${item.tColor}`}>{item.title}</h4>
                  <h4 className='text-lg'>{item.value}</h4>
                </div>
              )
            })
          }
        </div>

        {profile.attemptedQuizList && <div className='mt-10 space-y-5 max-h-[800px] overflow-scroll py-5 px-2'>
          <div className='text-center'>
            Attempted Quizes
          </div>
          {
            reverseArr(profile.attemptedQuizList).map((item,id)=>{
              return (
                <div key={id} className='shadow-box px-3 py-2 rounded-xl'>
                  <div className='flex justify-between items-center'>
                    <div className='font-semibold'>
                      {item.Title}
                    </div>
                    <div className='text-xs text-yellow-600 cursor-pointer underline' onClick={()=>{
                      navigate(`/quiz/${item.id}`)
                    }}>
                      {console.log(uid)}
                      {uid ===undefined ? "re-attempt" : "attempt"}
                    </div>
                  </div>

                  <div className='flex justify-between items-center'>
                    <div>
                      Score : <span className={item.finalScore>0 ? "text-green-700":"text-red-700 underline"}>{item.finalScore}</span>
                    </div>
                    <div className='text-xs text-blue-700'>
                    {item.lastSolved}
                    </div>
                  </div>
                </div>
              )
            })
          }
        </div>}

        {!uid && <div className='flex justify-between mt-10'>
          <div className='bg-green-800 text-white px-5 py-1 rounded-lg cursor-pointer' onClick={()=>{navigate("/profile/edit")}}>
            Edit Profile
          </div>
          <div className='bg-red-800 text-white px-5 py-1 rounded-lg cursor-pointer' onClick={logOut}>
            Sign Out
          </div>
        </div>}
      
      </div>
    </div>}</>
  )
}

export default Profile;