import React ,{useState ,useContext , useEffect} from 'react'
import { quizContext , userProfileContext} from '../context';
import {  updateInRealDB} from '../utils/commonFunc';
import { useNavigate } from 'react-router-dom';


function Result() {
  const { quiz , setQuiz } = useContext(quizContext);
  const {userProfile} = useContext(userProfileContext);
  console.log(quiz);
  const [result, setResult] = useState({});
  const navigate = useNavigate();


  const updateProfile = (data)=>{
    const lastDate = userProfile.lastActiveDate;
    const toDayDate = new Date().toLocaleDateString();
    const yesterdayDate = new Date();
    yesterdayDate.setDate(yesterdayDate.getDate()-1);

    console.log(lastDate , toDayDate ,yesterdayDate.toLocaleDateString());

    let cStreak = yesterdayDate.toLocaleDateString() === lastDate ? userProfile.currentStreak+1 : toDayDate === lastDate ? userProfile.currentStreak : 1;
    let mStreak = userProfile.maxStreak < cStreak ? cStreak : userProfile.maxStreak;
    let totalDays = toDayDate !== lastDate ? userProfile.totalActiveDays+1 : userProfile.totalActiveDays ;

    let attemptedQuizList = [];

    if(userProfile.attemptedQuizList){
      attemptedQuizList = userProfile.attemptedQuizList.filter(item => item.id !== quiz.id)
    }

    let totalMarks = data.finalScore;
    for(let i in attemptedQuizList){
      totalMarks = totalMarks + attemptedQuizList[i].finalScore;
    }

    let updatedProfile = {
      ...userProfile,
      currentStreak : cStreak,
      maxStreak : mStreak,
      totalActiveDays : totalDays,
      solvedQuizes : attemptedQuizList.length+1,
      avgMarks : totalMarks/(attemptedQuizList.length+1),
      attemptedQuizList : [...attemptedQuizList , {...data , id : quiz.id , "Title" :quiz.Title , lastSolved : toDayDate}],
      lastActiveDate : toDayDate
    }

    let endPoint = `users/${userProfile.email.split('@')[0]}`;
    updateInRealDB(endPoint , updatedProfile); 
  }

  const updateStreak = ()=>{
    const lastDate = userProfile.lastActiveDate;
    const toDayDate = new Date().toLocaleDateString();
    const yesterdayDate = new Date();
    yesterdayDate.setDate(yesterdayDate.getDate()-1);

    console.log(lastDate , toDayDate ,yesterdayDate.toLocaleDateString());

    let cStreak = yesterdayDate.toLocaleDateString() === lastDate ? userProfile.currentStreak+1 : toDayDate === lastDate ? userProfile.currentStreak : 1;
    let mStreak = userProfile.maxStreak < cStreak ? cStreak : userProfile.maxStreak;
    let totalDays = toDayDate !== lastDate ? userProfile.totalActiveDays+1 : userProfile.totalActiveDays ;

    let updatedProfile = {
      ...userProfile,
      currentStreak : cStreak,
      maxStreak : mStreak,
      totalActiveDays : totalDays,
      lastActiveDate : toDayDate
    }

    let endPoint = `users/${userProfile.email.split('@')[0]}`;
    updateInRealDB(endPoint , updatedProfile); 
  }

  console.log(userProfile);

  useEffect(() => {
      if(quiz.onGoing === false){
        navigate("/");
        return;
      }
      setQuiz({...quiz , onGoing : false})
      let attemptedQuestions = 0 ;
      let finalScore = 0 ;
      let correctQuestions =0;
      let minusMarking = parseFloat(quiz.MinusMarking);
      let markPerQuestion = parseFloat(quiz.MarkesPerQuestion);
      for(let i in quiz.list){
        let selected = quiz.list[i].selectedOption ;
        let correct = parseInt(quiz.list[i].question.CorrectOption);
        if(selected!==-1){
          attemptedQuestions = attemptedQuestions +1 ;
          if(selected === correct){
            correctQuestions = correctQuestions +1 ;
            finalScore = finalScore + markPerQuestion ;
          }
          else{
            finalScore = finalScore - minusMarking;
          }
        }
      }
      setResult({
        finalScore,
        correctQuestions,
        attemptedQuestions
      })
      if(quiz.noSaveOnDB){
        updateStreak();
        return ;
      }
      updateProfile({
        finalScore,
        correctQuestions,
        attemptedQuestions
      });
      console.log(finalScore , correctQuestions , attemptedQuestions , minusMarking , markPerQuestion);
  }, [])
  return (
    <div className='mt-5 px-5'>
      <div className='font-bold text-lg text-center'>Result</div>
      <div className='mt-5 space-y-5 flex flex-col justify-center items-center'>
        {
          [
            {title : "Total Score" , value : (result.finalScore + " 🎉")},
            {title : "Total  Questions" , value : quiz.total},
            {title : "Total Attempted Questions" , value : (result.attemptedQuestions + "")},
            {title : "Total Correct Questions" , value : (result.correctQuestions + " ✅")},
            {title : "Total Wrong Questions" , value : ((result.attemptedQuestions - result.correctQuestions) + " ❌")}
          ].map((item,id)=>{
            return (
              <div key={id} className='w-[300px] shadow-box rounded-xl text-center py-10'>
                <h4 className='text-orange-500 font-semibold text-lg mb-2'>{item.title}</h4>
                <h4 className='text-gray-600 text-lg mb-2'>{item.value}</h4>
              </div>
            )
          })
        }
      </div>

      <div className='text-red-500 font-extrabold text-lg text-center mt-10 shadow-box rounded-xl p-2' onClick={()=>{navigate("/solution")}}>See All Details & Solutions</div>
    </div>
  )
}

export default Result;