import React ,{useState ,useEffect ,  useContext} from 'react'
import { realDb } from "../firebase/firebaseConfig";
import { ref , onValue , remove} from "firebase/database";
import { userContext } from '../context';
import { useNavigate} from "react-router-dom";




const QuestionCard = ({item , id , selectedId , setSelectedId})=>{  
  const style = selectedId===id?"":'text-ellipsis whitespace-nowrap overflow-hidden'
  const [question, setQuestion] = useState({})

  useEffect(() => {
    console.log(item);
    const path = ref(realDb , `Questions/${item}`);
      onValue(path, (snapshot)=>{
        if(!snapshot.val())return ;
        console.log(snapshot.val());
        setQuestion(snapshot.val())
      })
  }, [item])
  

  return (
    <div className='shadow-box rounded-2xl items-center p-5 cursor-pointer relative'>
      <div onClick={()=>{
            if(selectedId === id){
              setSelectedId(null);
              return;
            }
            setSelectedId(id)
          }}>
        <div className={style}>
        {id+1}. <span className='font-semibold'>{question.Title}</span>
        </div>
        { question.Description && 
          <div className={`${style} mt-2`}>
            Description :-  {question.Description}
          </div>
        }
      </div>
      {
        selectedId === id && 
        <div className='space-y-2'>
          <div>
            Option 1. <p dangerouslySetInnerHTML={ {__html :question.Option1}}></p>
          </div>
          <div>
            Option 2. <p dangerouslySetInnerHTML={ {__html :question.Option2}}></p>
          </div>
          <div>
            Option 3. <p dangerouslySetInnerHTML={ {__html :question.Option3}}></p>
          </div>
          <div>
            Option 4. <p dangerouslySetInnerHTML={ {__html :question.Option4}}></p>
          </div>
          <div>
            Correct. {question.CorrectOption} rth Option ✅.
          </div>
          <div>
            Solution. {question.Solution? <span dangerouslySetInnerHTML={ {__html :question.Solution}}></span> : "Solution Not Given ! 🥸"}
          </div>

          <div>
            Question Image - {question.QuestionImageUrl ? question.QuestionImageUrl : "No Image ! 🧐"}
          </div>
          <div>
            Solution Image - {question.SolutionImageUrl ? question.QuestionImageUrl : "No Image ! 🧐"}
          </div>

          <div>
            Subject Tag :- {question.subjectTag ? <span className='border-gray-500 shadow-box px-3 rounded-lg ml-2 py-1'>{question.subjectTag}</span> : "No Tag ! 🧐"}
          </div>
          <div>
            Topic Tag :- {question.subjectTag ? <span className='border-gray-500 shadow-box px-3 rounded-lg ml-2 py-1'>{question.topicTag}</span> : "No Tag ! 🧐"}
          </div>
        </div>
      }

    </div>
  )
}



const QuizCard = ({item , id , selectedId , setSelectedId })=>{
  const [isConfirmBox, setisConfirmBox] = useState(false);
  const [qSelectedId, setQSelectedId] = useState(null);
  console.log(item);
  const { user } = useContext(userContext);
  console.log(user);
  const navigate = useNavigate();


  const deteleQuestion = ()=>{
    const path = ref(realDb , `Quizes/${item.key}`);
    remove(path).then(()=>{
      alert("Quiz is Deleted !");
    })
  }

  const style = selectedId===id?"":'text-ellipsis whitespace-nowrap overflow-hidden'
  return (
    <div className='shadow-box rounded-2xl items-center p-5 cursor-pointer relative'>
      <div onClick={()=>{
            if(user.email !== "examswala421@gmail.com"){
              navigate(`/quiz/${item.key}`);
              return ;
            }
            if(selectedId === id){
              setSelectedId(null);
              return;
            }
            setSelectedId(id)
          }}>
        <div className={style}>
        {id+1}. <span className='font-semibold'>{item.value.Title}</span>
        </div>
        { item.value.Description && 
          <div className={`${style} mt-2`}>
            Description :-  {item.value.Description}
          </div>
        }
      </div>
      {
        selectedId === id && 
        <div className='space-y-2 mt-2'>
          <div>
            Marks Per Question. {item.value.MarkesPerQuestion}
          </div>
          <div>
            Minus Marking. {item.value.MinusMarking}
          </div>
          <div>
            Total Time. {item.value.TimePerQuestion ? item.value.TimePerQuestion: item.value.TotalTime} min
          </div>

          <div>
            <div className='text-center text-lg font-extrabold'>Quiz Questions</div>
            <div className='space-y-5 my-5'>
              {
                item.value.QuestionList.map((item,id)=>{
                  return <QuestionCard key={id} item={item} id={id} selectedId={qSelectedId} setSelectedId={setQSelectedId}/>
                })
              }
            </div>
          </div>

          <div>
            <button className='bg-red-500 text-white px-5 py-1 rounded-lg' onClick={()=>{
              setisConfirmBox(!isConfirmBox)
            }}>Delete</button>
          </div>
          {
            isConfirmBox && 
            <div>
            <span className='text-orange-500'>Are You Sure ?</span> <button className='bg-red-700 text-white px-5 py-1 rounded-lg' onClick={deteleQuestion}>Yes</button>  <button className='bg-blue-500 text-white px-5 py-1 rounded-lg' onClick={()=>{setisConfirmBox(false)}}>No</button>
            </div>
          }
        </div>
      }

    </div>
  )
}





function QuizList() {
  const [quizList, setQuizList] = useState([]);
  const [filteredQuizList, setfilteredQuizList] = useState([])
  const [selectedId, setSelectedId] = useState(null);
  const [searchText, setSearchText] = useState("");

  const onChange = (e) => {
    setSearchText(e.target.value)
    searchQuiz(e.target.value);
  }

  const searchQuiz = (text)=>{
    text = text.trim()
    if(!text){
      setfilteredQuizList(quizList);
      return;
    }
    const latest = quizList.filter(item => item.value.Title.toLowerCase().startsWith(text.toLowerCase()));
    setfilteredQuizList(latest);
  }

  useEffect(() => {
    const path = ref(realDb , "Quizes/");
    onValue(path, (snapshot)=>{
      console.log(snapshot.val());
      var temp = [];
      for(let x in snapshot.val()){
        temp.push({
          key : x,
          value:snapshot.val()[x]
        })
      }
      console.log(temp);
      setQuizList(temp);
      setfilteredQuizList(temp);
    })
  }, [])


  return (
    <div>
      <div className='text-center text-lg font-extrabold'>Quizes List</div>
      <div className='space-y-5 mt-10 px-5'>
        <input type="text" className='border border-gray-200 rounded-lg px-2 py-1 w-full' placeholder='Search Quizes...' value={searchText} onChange={onChange}/>
      </div>
      <div className='space-y-5 mt-10 px-5'>
        {
          filteredQuizList.map((item,id)=>{
            return <QuizCard key={id} item={item} id={id} selectedId={selectedId} setSelectedId={setSelectedId} />
          })
        }
      </div>
    </div>
  )
}

export default QuizList