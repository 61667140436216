import React ,{useEffect , useState} from 'react'
import { getAllExamsTags , getAllSubjectTags , getAllTopicTags , saveInRealDB} from '../utils/commonFunc';

function Tags() {
    const [examTags, setExamTags] = useState([]);
    const [subjectTags, setSubjectTags] = useState([]);
    const [topicTags, setTopicTags] = useState([]);
    const [data, setData] = useState([]);

    const [newExamTag, setnewExamTag] = useState("");
    const [newSubjectTag, setnewSubjectTag] = useState("");
    const [newTopicTag, setnewTopicTag] = useState("");


    useEffect(() => {
        getAllExamsTags(setExamTags);
        getAllSubjectTags(setSubjectTags);
        getAllTopicTags(setTopicTags);
      }, [])
    

    useEffect(() => {
        setData([
            {
                Title : "Exams Tag",
                list : examTags,
                inputTitle : "New Exam Tag*",
                onAdd:()=>{saveInRealDB("Tags/Exams",newExamTag); setnewExamTag("")},
                onChange : (e)=>{setnewExamTag(e.target.value)},
                value: newExamTag
            },
            {
                Title : "Subjects Tag",
                list : subjectTags,
                inputTitle : "New Subject Tag*",
                onAdd:()=>{saveInRealDB("Tags/Subjects",newSubjectTag); setnewSubjectTag("")},
                onChange : (e)=>{setnewSubjectTag(e.target.value)},
                value: newSubjectTag
            },
            {
                Title : "Topics Tag",
                list : topicTags,
                inputTitle : "New topic Tag*",
                onAdd:()=>{saveInRealDB("Tags/Topics",newTopicTag); setnewTopicTag("")},
                onChange : (e)=>{setnewTopicTag(e.target.value)},
                value: newTopicTag
            }
        ])
    }, [examTags,subjectTags,topicTags , newExamTag , newSubjectTag , newTopicTag])
    

    return (
        <div className='p-5'>
            <h3 className='text-center font-extrabold text-lg'>All Tags</h3>
            <div className='space-y-10'>
                {
                    data.map((tagName,idTag)=>{
                        return (
                            <div key={idTag}>
                                <h3 className='font-bold underline mt-5'>{tagName.Title}</h3>
                                <div className='mt-4 flex flex-wrap justify-start'>
                                    {
                                        tagName.list.map((item,id)=>{
                                            return <div key={id} className="shadow-box p-2 rounded-xl mr-5 mb-2">{item}</div>
                                        })
                                    }
                                </div>
                                <div>
                                    <div className='space-y-2'>
                                        <h3>{tagName.inputTitle}</h3>
                                        <input type="text" className='border border-gray-400 rounded-lg px-2 py-1 w-[200px]' placeholder='Enter Email' value={tagName.value} onChange={tagName.onChange}/>
                                        <button className='bg-blue-500 ml-5 px-5 py-1 text-white rounded-lg' onClick={tagName.onAdd}>Add</button>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default Tags;