import React , {useState , useEffect  , useContext} from 'react'
import { getAllSubjectTags , getAllTopicTags } from '../utils/commonFunc';
import Dropdown from './common/Dropdown';
import { realDb } from '../firebase/firebaseConfig';
import { ref, query, equalTo , onValue, orderByChild , limitToFirst} from "firebase/database";
import { quizContext } from '../context';
import go from "../assets/images/go.svg";
import { useNavigate } from 'react-router-dom';


function MultipleChoiceQuestions() {
  const [subjectTags, setSubjectTags] = useState([]);
  const [topicTags, setTopicTags] = useState([]);
  const [subjectTag, setSubjectTag] = useState('Select Subject Tag');
  const [topicTag, setTopicTag] = useState("Select Topic Tag");
  const { quiz , setQuiz } = useContext(quizContext);
  const [isGoTimerVisible, setisGoTimerVisible] = useState(false);
  const [goTimer, setgoTimer] = useState(5);
  const naviate = useNavigate();
  

  // console.log(quiz);

  useEffect(() => {
    getAllSubjectTags(setSubjectTags);
    getAllTopicTags(setTopicTags);
  }, [])

  const startGoTimer = ()=>{
        setisGoTimerVisible(true);
        let timer = 5;
        const internval = setInterval(() => {
        setgoTimer(timer);
        timer = timer-1;
        if(timer<-1){
            clearInterval(internval);
            setisGoTimerVisible(false);
            setgoTimer(5);
            naviate("/mcq-practice");
        }
        }, 1000);
        setQuestionsList()
    }

  const createQuiz = (QuestionList)=>{
    const questions = [];
    for(let id in QuestionList){
      if(topicTag !== "Select Topic Tag" && QuestionList[id].topicTag === topicTag){
        questions.push({question : QuestionList[id] , selectedOption : -1});
      }
      else if(topicTag === "Select Topic Tag"){
        questions.push({question : QuestionList[id] , selectedOption : -1});
      }
    }
    if(!questions.length){
        alert("No Question Available On This Filter 😣");
        return ;
    }
    const title = subjectTag !== 'Select Subject Tag' ? subjectTag : topicTag !== "Select Topic Tag" ?topicTag : "Practice"
    setQuiz({Title : title , "questionsList" : questions , "currIndex" : Math.floor(Math.random() * questions.length) , list :[] , "total":questions.length , "onGoing" : true ,noSaveOnDB : true ,MinusMarking : 0 , MarkesPerQuestion : 4 });
  }

  const setQuestionsList = ()=>{
    if(subjectTag === 'Select Subject Tag' && topicTag === "Select Topic Tag"){
        const Dref = query(ref(realDb, '/Questions') , limitToFirst(200));
        onValue(Dref, (snapshot) => {
            if(snapshot.val()){
                createQuiz(snapshot.val());
            }
            else{
                alert("No Question Available On This Filter 😣")
            }
        }, {
            onlyOnce: true
        });
    }
    if(topicTag !== "Select Topic Tag"){
        const Dref = query(ref(realDb ,'/Questions') , orderByChild("topicTag") , equalTo(topicTag) , limitToFirst(200));
        onValue(Dref, (snapshot) => {
            if(snapshot.val()){
                createQuiz(snapshot.val());
            }
            else{
                alert("No Question Available On This Filter 😣")
            }
        }, {
            onlyOnce: true
        });
    }
    if(subjectTag !== 'Select Subject Tag'){
        const Dref = query(ref(realDb ,'/Questions') , orderByChild("subjectTag") , equalTo(subjectTag) , limitToFirst(200));
        onValue(Dref, (snapshot) => {
            if(snapshot.val()){
                createQuiz(snapshot.val());
            }
            else{
                alert("No Question Available On This Filter 😣")
            }
        }, {
            onlyOnce: true
        });
    }
  }
  

  return (
    <div className='mt-5 px-5'>
        {isGoTimerVisible &&
        <div className='go-screen flex flex-col justify-center items-center'>
            <img src={go} alt="go" />
            <div className='text-white font-extrabold text-8xl'>{goTimer}</div>
        </div>
        }
        <div className='space-y-5'>
            <div className='text-center text-lg font-bold'>
                Multiple Choice Questions
            </div>
            <div>
             These are mcq type questions with no time limit , these are specially designed for your practice . Here you can select the subject and topic and start practice .
            </div>
        </div>
        <div className='flex justify-start items-center mt-5 flex-wrap'>
          <Dropdown className="mr-5" list={subjectTags} selected={subjectTag} setSelected={setSubjectTag} title='Select Subject Tag'/>
          <Dropdown className="mr-5" list={topicTags} selected={topicTag} setSelected={setTopicTag} title='Select Topic Tag'/>
          <div className='mt-2 cursor-pointer' onClick={()=>{
            setSubjectTag('Select Subject Tag');
            setTopicTag("Select Topic Tag");
          }}>Clear Filter &#10005;</div>
      </div>
      <button className='mt-10 bg-green-700 text-white text-lg rounded-lg px-3 py-1' onClick={startGoTimer} >
        Start Practice
      </button>
    </div>
  )
}

export default MultipleChoiceQuestions;