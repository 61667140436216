import React from 'react'
import { useNavigate} from "react-router-dom";

function Admin() {
    const navigate = useNavigate();
    const features = [
        {
          title:"All Questions",
          to:"/qel",
          desc:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,"
        },
        {
          title:"Add New Question",
          to:"/c-qe",
          desc:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,"
        },
        {
          title:"All Quizes",
          to:"/qzl",
          desc:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,"
        },
        {
            title:"Add New Quiz",
            to:"/c-qz",
            desc:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,"
        },
        {
          title:"Image Gallery",
          to:"/gallery",
          desc:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,"
        },
        {
          title:"Add New Tags",
          to:"/tags",
          desc:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,"
        }
      ]
  return (
    <div>
        <div className='text-2xl text-center font-extrabold my-10'>
            Welcome To Admin Page
        </div>
        <div className='grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-2 gap-5 px-5 mt-3'>
          {
            features.map((item,id)=>{
              return (
                <div key={id} className='shadow-box rounded-2xl flex flex-col justify-center items-center p-5 cursor-pointer' onClick={()=>{
                    navigate(item.to)
                }}>
                  <div className='font-semibold text-lg mt-1'>{item.title}</div>
                  <div className='text-xs mt-1'>{item.desc}</div>
                </div>
              )
            })
          }
        </div>
    </div>
  )
}

export default Admin;