import React ,  {useContext}from 'react'
import { useNavigate} from "react-router-dom";
import feat1 from "../assets/images/feat1.jpeg";
import feat2 from "../assets/images/feat2.jpg";
import feat3 from "../assets/images/feat3.png";
import { userContext } from '../context';


function Home() {
  const navigate = useNavigate();
  const { user } = useContext(userContext);
  console.log(user);

  const features = [
    {
      img:feat1,
      title:"Multiple Choice Questions",
      desc:"multiple choice questions that help you to boost your knowledge, solve many questions and get ready for  competition",
      to: "/mcq"
    },
    {
      img:feat2,
      title:"Timed Quiz",
      desc:'Timed test make you fearless about your upcoming exam " Time is money" so use this and made yourself successful ',
      to: "/qzl"
    },
    {
      img:feat3,
      title:"Leaderboard",
      desc:"your word,your team , your decision, your life, your role,  your action, your knowledge that's make your a leader be a leader and  make a rank",
      to: "/leader-board"
    }
  ]
  return (
    <div>
      <div className='h-[300px] xl:h-[700px] lg:h-[600px] md:h-[500px] font-extrabold text-white home-header '>
          <div className='text-3xl xl:text-7xl lg:text-6xl md:text-5xl flex justify-center items-center flex-col'>
            <div>Test your  <br />  Navy , Airforce <br />  knowledge</div>
            <div className='text-sm xl:text-xl lg:text-lg md:text-base mt-5 bg-slate-300 text-black px-2 py-1 rounded-lg cursor-pointer tracking-wider' onClick={()=>{
              navigate("/qzl")
            }}>
              Start Quiz
            </div>
          </div>
      </div>

      <div className='mt-10'>
        <h3 className='text-center text-lg font-semibold'>Features</h3>

        <div className='grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-3  gap-5 px-5 mt-3'>
          {
            features.map((item,id)=>{
              return (
                <div key={id} className='shadow-box rounded-2xl flex flex-col justify-center items-center p-5 cursor-pointer' onClick={()=>{
                  if(item.to){
                    navigate(item.to);
                  }
                }}>
                  <div>
                    <img width={200} src={item.img} alt="" />
                  </div>
                  <div className='font-semibold text-lg mt-1'>{item.title}</div>
                  <div className='text-sm mt-1'>{item.desc}</div>
                </div>
              )
            })
          }
        </div>
        
      </div>


      {user.email === "examswala421@gmail.com" && <div className='flex justify-center items-center my-10'>
        <button className='bg-blue-500 text-white px-5 py-1 rounded-xl' onClick={()=>{
          navigate("/admin")
        }}>Go To Admin Pannel</button>
      </div>}
    </div>
  )
}

export default Home;