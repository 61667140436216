import React ,{useState , useEffect , useContext} from 'react'
import { quizContext } from '../context';
import { useNavigate } from 'react-router-dom';



function MCQ() {
  const { quiz , setQuiz } = useContext(quizContext);
  const [isConfirmBoxVisible, setisConfirmBoxVisible] = useState(false);
  const [selectedOption, setselectedOption] = useState(-1);
  const navigate = useNavigate();

  console.log(quiz);

  useEffect(() => {
    if(quiz.onGoing === false){
      navigate("/");
    }
  }, [])

  const guessNextQuestion = (round)=>{
    if(round>1000){
        alert("You Have Solved Good Number Of Questions Let's See Results");
        navigate("/result");
        return 0;
    }
    let index = Math.floor(Math.random() * quiz.total);
    console.log(index);
    if(index !==undefined && quiz.questionsList[index].selectedOption === -1){
        return index;
    }
    return guessNextQuestion(round+1);
  }

  const nextQuestion = ()=>{
    let index = guessNextQuestion(0);
    if(index === undefined ){
        alert("Something went wrong !")
    }
    console.log(index , "Setting the index !");
    quiz.list.push(quiz.questionsList[quiz.currIndex]);
    setQuiz({...quiz , currIndex : index , list : [...quiz.list]});
    setselectedOption(quiz.questionsList[index].selectedOption);
  }


  const handleSelectedOption = (option)=>{
    if(option === selectedOption){
      console.log("Same Option Selected To remove That !");
      option = -1;
    }
    setselectedOption(option);
    console.log("Selected Option is" , option);
    quiz.questionsList[quiz.currIndex].selectedOption = option;
    setQuiz({...quiz , questionsList : [...quiz.questionsList]});
  }

  const submitQuiz = ()=>{
    setisConfirmBoxVisible(true);
  }


  return (
    <div>
        { quiz.onGoing && <div>
      {isConfirmBoxVisible && <div className='go-screen flex flex-col justify-center items-center'>
        <div className='font-extrabold text-xl text-white text-center px-2 mt-3 space-x-2'><span>Are You Sure ?</span> <button className='bg-red-700 text-white px-5 py-1 rounded-lg shadow-box' onClick={()=>{
          navigate("/result");
          setisConfirmBoxVisible(false);
        }}>Yes</button>  
        <button className='bg-green-700 text-white px-5 py-1 rounded-lg shadow-box' onClick={()=>{
          setisConfirmBoxVisible(false);
        }}>No</button></div>
      </div>}
      <div className='px-5'>
        <div className='flex justify-end my-5'>
          <div className='text-lg font-bold bg-green-700 text-white text-center py-2 rounded-lg px-2 mr-5 cursor-pointer' onClick={submitQuiz}>End Practice And See Result</div>
        </div>
        <div className='mt-5 font-semibold text-lg'>
          Question. {quiz.questionsList[quiz.currIndex].question.Title}
        </div>

        {quiz.questionsList[quiz.currIndex].question.QuestionImgurl.toLowerCase() !=="null" &&<div>
          <img src={quiz.questionsList[quiz.currIndex].question.QuestionImgurl} alt="Question" width={300}/>
        </div>}


        {<div className='mt-5 text-lg'>
        <span dangerouslySetInnerHTML={ {__html :quiz.questionsList[quiz.currIndex].question.Description}}></span>
        </div>}

  
        <ul className='space-y-2 mt-5'>
          <li className='flex justify-start space-x-3' onClick={()=>{handleSelectedOption(1)}}><input checked ={selectedOption === 1} type="radio" name="option" value={1} onChange={()=>{}}/> <span dangerouslySetInnerHTML={ {__html :quiz.questionsList[quiz.currIndex].question.Option1}}></span> </li>
          <li className='flex justify-start space-x-3' onClick={()=>{handleSelectedOption(2)}}><input checked ={selectedOption === 2} type="radio" name="option" value={2} onChange={()=>{}}/> <span dangerouslySetInnerHTML={ {__html :quiz.questionsList[quiz.currIndex].question.Option2}}></span></li>
          <li className='flex justify-start space-x-3' onClick={()=>{handleSelectedOption(3)}}><input checked ={selectedOption === 3} type="radio" name="option" value={3} onChange={()=>{}}/> <span dangerouslySetInnerHTML={ {__html :quiz.questionsList[quiz.currIndex].question.Option3}}></span></li>
          <li className='flex justify-start space-x-3' onClick={()=>{handleSelectedOption(4)}}><input checked ={selectedOption === 4} type="radio" name="option" value={4} onChange={()=>{}}/> <span dangerouslySetInnerHTML={ {__html :quiz.questionsList[quiz.currIndex].question.Option4}}></span></li>
        </ul>

        <div className='flex justify-between items-center'>
          <button className='bg-blue-600 text-white mt-10 px-5 py-1 rounded-lg cursor-pointer' onClick={nextQuestion}>{"Next ->"}</button>
        </div>
      </div>
    </div>}
    </div>
  )
}

export default MCQ;