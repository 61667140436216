import { useState , useEffect } from 'react';
import './App.css';
import LogIn from './components/LogIn';
import SignUp from './components/SignUp';
import Home from './components/Home';
import Quiz from './components/Quiz';
import Result from './components/Result';
import Solution from './components/Solution';
import Profile from './components/Profile';
import EditProfile from './components/EditProfile';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Admin from './components/Admin';
import QuestionsList from './components/QuestionsList';
import QuizList from './components/QuizList';
import ImageGallery from './components/ImageGallery';
import Tags from './components/Tags';
import LeaderBoard from './components/LeaderBoard';
import MultipleChoiceQuestions from './components/MultipleChoiceQuestions';
import MCQ from './components/MCQ';

import CreateQues from './components/CreateQues';
import EditQuestion from './components/EditQuestion';
import CreateQuiz from './components/CreateQuiz';


import { userContext , quizContext , userProfileContext } from './context';
import { auth } from './firebase/firebaseConfig';

import { sendVerificationLink, getUserProfile } from './utils/commonFunc';


// Routing Imports
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Questions from './components/Questions';

function App() {
  const [user, setUser] = useState(true);
  const [userProfile, setUserProfile] = useState({});
  const [quiz, setQuiz] = useState({onGoing : false});
  
  useEffect(() => {
    auth.onAuthStateChanged(curruser=>{
      if(curruser){
        setUser(curruser)
      }else{
        setUser(null);
      }
    })
  }, [])

  useEffect(() => {
    getUserProfile(setUserProfile);
  }, [user])
  
  
  return (
    <userContext.Provider value={{ user , setUser }}>
      <quizContext.Provider value = {{quiz , setQuiz}}>
        <userProfileContext.Provider value = {{userProfile , setUserProfile}}>
          <BrowserRouter>
            <div className="App">
              <Navbar/>
                <div className='min-h-[100vh]'>
                  <Routes>
                      {/* Free End Point For Public  */}
                      <Route exact path="/profile/:uid" element={<Profile/>} />


                      {/* Ristricted End Points  */}
                      <Route exact path="/profile/edit" element={user ? <EditProfile/> : <Navigate to="/signin" />} />
                      <Route exact path="/profile" element={user ? <Profile/> : <Navigate to="/signin" />} />
                      <Route exact path="/questions" element={user ? <Questions/> : <Navigate to="/signin" />} />
                      <Route exact path="/result" element={user ? <Result/> : <Navigate to="/signin" />} />
                      <Route exact path="/solution" element={user ? <Solution/> : <Navigate to="/signin" />} />
                      <Route exact path="/leader-board" element={user ? <LeaderBoard/> : <Navigate to="/signin" />} />
                      <Route exact path="/mcq-practice" element={user ? <MCQ/> : <Navigate to="/signin" />} />
                      <Route exact path="/mcq" element={user ? auth.currentUser && auth.currentUser.emailVerified? <MultipleChoiceQuestions/> : <h2 className='text-center my-5'>Email is not verified. <span className='text-orange-600 cursor-pointer ml-3 underline' onClick={sendVerificationLink}>Send Verification Link</span></h2> : <Navigate to="/signin" />} />
                      <Route exact path="/quiz/:id" element={user ? <Quiz/> : <Navigate to="/signin" />} />
                      <Route exact path="/qzl" element={user ? auth.currentUser && auth.currentUser.emailVerified? <QuizList /> : <h2 className='text-center my-5'>Email is not verified. <span className='text-orange-600 cursor-pointer ml-3 underline' onClick={sendVerificationLink}>Send Verification Link</span></h2> : <Navigate to="/signin" />} />
                      <Route exact path="/" element={user ? <Home /> : <Navigate to="/signin" />} />

                      {/* for admin only */}
                      <Route exact path="/admin" element={user && user.email === "examswala421@gmail.com" ? <Admin /> : "404"} />
                      <Route exact path="/tags" element={user && user.email === "examswala421@gmail.com" ? <Tags /> : "404"} />
                      <Route exact path="/c-qz" element={user && user.email === "examswala421@gmail.com" ? <CreateQuiz/> : "404"} />
                      <Route exact path="/c-qe" element={user && user.email === "examswala421@gmail.com" ? <CreateQues /> : "404"} />
                      <Route exact path="/eq/:id" element={user && user.email === "examswala421@gmail.com" ? <EditQuestion /> : "404"} />
                      <Route exact path="/qel" element={user  && user.email === "examswala421@gmail.com" ? <QuestionsList/> : "404"} />
                      <Route exact path="/gallery" element={user && user.email === "examswala421@gmail.com" ? <ImageGallery /> : "404"} />

                      {/* before Signin */}
                      <Route exact path="/signin" element={!user ? <LogIn /> : <Navigate to="/" />} />
                      <Route exact path="/signup" element={!user ? <SignUp /> : <Navigate to="/" />} />
                      <Route exact path="*" element={"404"} />
                    </Routes>
                </div>
              <Footer/>
            </div>
          </BrowserRouter>
        </userProfileContext.Provider>
      </quizContext.Provider>
    </userContext.Provider>
  );
}

export default App;
