import React ,{useState} from 'react'
import QuestionsList from './QuestionsList'
import Loader from './Loader';
import { set, ref as dRef ,push} from "firebase/database";
import { realDb } from "../firebase/firebaseConfig";


const QuestionCard = ({item , id , selectedId , setSelectedId})=>{  
  const style = selectedId===id?"":'text-ellipsis whitespace-nowrap overflow-hidden'
  return (
    <div className='shadow-box rounded-2xl items-center p-5 cursor-pointer relative'>
      <div onClick={()=>{
            if(selectedId === id){
              setSelectedId(null);
              return;
            }
            setSelectedId(id)
          }}>
        <div className={style}>
        {id+1}. <span className='font-semibold'>{item.value.Title}</span>
        </div>
        { item.value.Description && 
          <div className={`${style} mt-2`}>
            Description :-  <p dangerouslySetInnerHTML={ {__html :item.value.Description}}></p>
          </div>
        }
      </div>
      {
        selectedId === id && 
        <div className='space-y-2'>
           <div>
            Option 1. <p dangerouslySetInnerHTML={ {__html :item.value.Option1}}></p>
          </div>
          <div>
            Option 2. <p dangerouslySetInnerHTML={ {__html :item.value.Option2}}></p>
          </div>
          <div>
            Option 3. <p dangerouslySetInnerHTML={ {__html :item.value.Option3}}></p>
          </div>
          <div>
            Option 4. <p dangerouslySetInnerHTML={ {__html :item.value.Option4}}></p>
          </div>
          <div>
            Correct. {item.value.CorrectOption} rth Option ✅.
          </div>
          <div>
            Solution. {item.value.Solution?<span dangerouslySetInnerHTML={ {__html :item.value.Solution}}></span> : "Solution Not Given ! 🥸"}
          </div>

          <div>
            Question Image - {item.value.QuestionImageUrl ? item.value.QuestionImageUrl : "No Image ! 🧐"}
          </div>
          <div>
            Solution Image - {item.value.SolutionImageUrl ? item.value.QuestionImageUrl : "No Image ! 🧐"}
          </div>

          <div>
            Subject Tag :- {item.value.subjectTag ? <span className='border-gray-500 shadow-box px-3 rounded-lg ml-2 py-1'>{item.value.subjectTag}</span> : "No Tag ! 🧐"}
          </div>
          <div>
            Topic Tag :- {item.value.subjectTag ? <span className='border-gray-500 shadow-box px-3 rounded-lg ml-2 py-1'>{item.value.topicTag}</span> : "No Tag ! 🧐"}
          </div>
        </div>
      }

    </div>
  )
}





function CreateQuiz() {
  const [selectedId, setSelectedId] = useState(null);
  const [quizQuestiuons, setQuizQuestiuons] = useState([]);
  const [showQuestions, setshowQuestions] = useState(false);

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [marksPerQuestion, setMarksPerQuestion] = useState("");
  const [minusMarking, setMinusMarking] = useState("");
  const [totalTime, setTotalTime] = useState("");

  const [isLoader, setisLoader] = useState(false);


  const createQuiz = ()=>{
    setTitle(title.trim());
    setDescription(description.trim());
    setMarksPerQuestion(marksPerQuestion.trim());
    setMinusMarking(minusMarking.trim());
    setTotalTime(totalTime.trim());

    if(!title || !description || !marksPerQuestion || !minusMarking || !totalTime){
      alert("Please Fill All Mandatory Fields 🙁");
      return;
    }

    if(quizQuestiuons.length<5){
      alert("Please Select At least 5 Questions 😠");
      return ;
    }

    setisLoader(true);

    let list = []

    for(let item in quizQuestiuons){
      list.push(quizQuestiuons[item].key);
    }

    console.log(list);
    const dbref = dRef(realDb, "Quizes/");
      const pushQuestion = push(dbref);
      const data = {
          "Title": title,
          "Description": description,
          "MarkesPerQuestion":marksPerQuestion,
          "MinusMarking" : minusMarking,
          "TotalTime" : totalTime,
          "QuestionList":list
      }
      console.log(data);
      set(pushQuestion, data);
      setisLoader(false);
      alert("Quiz Created 👍");
      setTitle("");
      setDescription("");
      setMarksPerQuestion("");
      setMinusMarking("");
      setTotalTime("");
      setQuizQuestiuons([]);
  }
  return (
    <div className='px-5 space-y-5 mt-10'>
      {isLoader && <Loader text='Uploading ...'/>}
      <h5 className='text-lg font-semibold mb-10'>Create New Quiz</h5>
      <div>
        <h3>Title Of Quiz*</h3>
        <input type="text" className='border border-gray-400 rounded-lg px-2 py-1 w-full' placeholder='which is best quiz web site ?' value={title} onChange={(e)=>{setTitle(e.target.value)}}/>
      </div>

      <div>
        <h3>Description*</h3>
        <textarea type="text" className='border border-gray-400 rounded-lg px-2 py-1 w-full' placeholder='Description of Quiz' value={description} onChange={(e)=>{setDescription(e.target.value)}} />
      </div>

      <div>
        <h3>Marks Per Correct Question*</h3>
        <input type="number" className='border border-gray-400 rounded-lg px-2 py-1 w-full' placeholder='1' value={marksPerQuestion} onChange={(e)=>{setMarksPerQuestion(e.target.value)}}/>
      </div>

      <div>
        <h3>Minus Marking*</h3>
        <input type="number" className='border border-gray-400 rounded-lg px-2 py-1 w-full' placeholder='1' value={minusMarking} onChange={(e)=>{setMinusMarking(e.target.value)}}/>
      </div>

      <div>
        <h3>Total Time ( in minutes )*</h3>
        <input type="number" className='border border-gray-400 rounded-lg px-2 py-1 w-full' placeholder='1'  value={totalTime} onChange={(e)=>{setTotalTime(e.target.value)}}/>
      </div>

      <div>
      <div className='text-center text-lg font-extrabold'>Quiz Questions</div>
      <div className='space-y-5 mt-10 px-5'>
        {quizQuestiuons.length===0 && <div className='font-bold'>No Question Selected</div>}
        {
          quizQuestiuons.map((item,id)=>{
            return <QuestionCard key={id} item={item} id={id} selectedId={selectedId} setSelectedId={setSelectedId}/>
          })
        }
      </div>
    </div>

    <div className='cursor-pointer bg-yellow-600 text-white px-5 py-1 rounded-lg' onClick={()=>{
      setshowQuestions(!showQuestions)
    }}>{showQuestions ? "Hide Questions List":"Show Questions List"}</div>
      <div className='py-5 h-[500px] box shadow-inner border-2' style={{
        display:showQuestions?"":"none"
      }}>
        <QuestionsList fromCreateQuiz={true} quizQuestiuons={quizQuestiuons} setQuizQuestiuons={setQuizQuestiuons}/>
      </div>

      <div className='w-[300px]'>
          <button className='bg-blue-500 text-white px-5 py-1 rounded-xl' onClick={createQuiz}>Create Quiz</button>
      </div>
    </div>
  )
}

export default CreateQuiz;