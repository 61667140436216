import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";
import { getStorage } from "firebase/storage";



// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBr1kyssKdG7fnK5owdMdQ5L3NeGwbwXDs",
  authDomain: "exams-wala.firebaseapp.com",
  databaseURL: "https://exams-wala-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "exams-wala",
  storageBucket: "exams-wala.appspot.com",
  messagingSenderId: "655829824813",
  appId: "1:655829824813:web:64f2013dd503f7a2d98fa4",
  measurementId: "G-E9B9QTLDNK"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const realDb = getDatabase(app);
export const storage = getStorage(app);