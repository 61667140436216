import React , {useState , useEffect} from 'react'
import { realDb } from "../firebase/firebaseConfig";
import { ref , onValue , remove} from "firebase/database";
import { useNavigate } from 'react-router-dom';
import Loader from './Loader';
import { getAllTopicTags , getAllSubjectTags } from '../utils/commonFunc';
import Dropdown from './common/Dropdown';







const QuestionCard = ({item , id , selectedId , setSelectedId ,  fromCreateQuiz = false ,quizQuestiuons = [], setQuizQuestiuons = ()=>{}})=>{
  const [isConfirmBox, setisConfirmBox] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const navigate = useNavigate();
  

  const deteleQuestion = ()=>{
    const path = ref(realDb , `Questions/${item.key}`);
    remove(path).then(()=>{
      alert("Question is Deleted !");
    })
  }

  useEffect(() => {
    setIsChecked(quizQuestiuons.includes(item));
  },[item ,quizQuestiuons])
  

  const onSelectQuesForQuiz = (e)=>{
    console.log(e.target.checked);
    setIsChecked(e.target.checked);
    if(e.target.checked){
      setQuizQuestiuons([...quizQuestiuons,item])
      console.log([...quizQuestiuons,item]);
    }
    else{
      let temp = quizQuestiuons.filter(curr=> curr.key!==item.key);
      setQuizQuestiuons(temp);
    }
  }


  const style = selectedId===id?"":'text-ellipsis whitespace-nowrap overflow-hidden'
  return (
    <div className='shadow-box rounded-2xl items-center p-5 cursor-pointer relative'>
      <div onClick={()=>{
            if(selectedId === id){
              setSelectedId(null);
              return;
            }
            setSelectedId(id)
          }}>
        <div className={style}>
        {id+1}. <span className='font-semibold'>{item.value.Title}</span>
        </div>
        { item.value.Description && 
          <div className={`${style} mt-2`}>
            Description :-  <p dangerouslySetInnerHTML={ {__html :item.value.Description}}></p>
          </div>
        }
      </div>
      {
        selectedId === id && 
        <div className='space-y-2'>
          <div>
            Option 1. <p dangerouslySetInnerHTML={ {__html :item.value.Option1}}></p>
          </div>
          <div>
            Option 2. <p dangerouslySetInnerHTML={ {__html :item.value.Option2}}></p>
          </div>
          <div>
            Option 3. <p dangerouslySetInnerHTML={ {__html :item.value.Option3}}></p>
          </div>
          <div>
            Option 4. <p dangerouslySetInnerHTML={ {__html :item.value.Option4}}></p>
          </div>
          <div>
            Correct. {item.value.CorrectOption} rth Option ✅.
          </div>
          <div>
            Solution. {item.value.Solution? <span dangerouslySetInnerHTML={ {__html :item.value.Solution}}></span> : "Solution Not Given ! 🥸"}
          </div>

          <div>
            Question Image - {item.value.QuestionImgurl !== "null" ? item.value.QuestionImgurl : "No Image ! 🧐"}
          </div>
          <div>
            Solution Image - {item.value.SolutionImgUrl !== "null" ? item.value.SolutionImgUrl : "No Image ! 🧐"}
          </div>

          <div>
            Subject Tag :- {item.value.subjectTag && item.value.subjectTag !== "Select Subject Tag" ? <span className='border-gray-500 shadow-box px-3 rounded-lg ml-2 py-1'>{item.value.subjectTag}</span> : "No Tag ! 🧐"}
          </div>
          <div>
            Topic Tag :- {item.value.topicTag  && item.value.topicTag !== "Select Topic Tag" ? <span className='border-gray-500 shadow-box px-3 rounded-lg ml-2 py-1'>{item.value.topicTag}</span> : "No Tag ! 🧐"}
          </div>

          {
            !fromCreateQuiz &&
            <div>
              <button className='bg-blue-500 text-white px-5 py-1 rounded-lg' onClick={()=>{
                navigate(`/eq/${item.key}`)
              }}>Edit</button>
            </div>
          }

          {
            !fromCreateQuiz &&
            <div>
              <button className='bg-red-500 text-white px-5 py-1 rounded-lg' onClick={()=>{
                setisConfirmBox(!isConfirmBox)
              }}>Delete</button>
            </div>
          }
          {
            isConfirmBox && 
            <div>
            <span className='text-orange-500'>Are You Sure ?</span> <button className='bg-red-700 text-white px-5 py-1 rounded-lg' onClick={deteleQuestion}>Yes</button>  <button className='bg-blue-500 text-white px-5 py-1 rounded-lg' onClick={()=>{setisConfirmBox(false)}}>No</button>
            </div>
          }
        </div>
      }

      {
        fromCreateQuiz && 
        <div className='absolute top-2 right-2'><input type="checkbox" onChange={onSelectQuesForQuiz} checked={isChecked}/></div>
      }

    </div>
  )
}

function QuestionsList({
  fromCreateQuiz = false,
  quizQuestiuons = [],
  setQuizQuestiuons = ()=>{}
}) {
  const [questionList, setQuestionList] = useState([]);
  const [filteredQuestionList, setfilteredQuestionList] = useState([])
  const [selectedId, setSelectedId] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [isLoader, setisLoader] = useState(true);
  const [subjectTags, setSubjectTags] = useState([]);
  const [topicTags, setTopicTags] = useState([]);
  const [subjectTag, setSubjectTag] = useState('Select Subject Tag');
  const [topicTag, setTopicTag] = useState("Select Topic Tag");

  const onChange = (e) => {
    setSearchText(e.target.value.trim())
  }

  const searchQuestion = ()=>{
    if(!searchText && subjectTag === 'Select Subject Tag' && topicTag === "Select Topic Tag"){
      setfilteredQuestionList(questionList);
      return;
    }
    let latest = questionList;
    console.log(latest);


    if(searchText){
      latest = latest.filter(item => item.value.Title.toLowerCase().startsWith(searchText.toLowerCase()));
    }
    console.log(latest);
    if(subjectTag !== 'Select Subject Tag'){
      latest = latest.filter(item => {
        if (item.value.subjectTag){
          return item.value.subjectTag.toLowerCase() === (subjectTag.toLowerCase())
        }
        return false;
      });
    }
    console.log(latest);
    if(topicTag !== "Select Topic Tag"){
      latest = latest.filter(item => {
        if(item.value.topicTag){
          return item.value.topicTag.toLowerCase()===(topicTag.toLowerCase());
        }
        return false;
      });
    }
    setfilteredQuestionList(latest);
  }

  useEffect(() => {
    const path = ref(realDb , "Questions/");
    onValue(path, (snapshot)=>{
      console.log(snapshot.val());
      var temp = [];
      for(let x in snapshot.val()){
        temp.push({
          key : x,
          value:snapshot.val()[x]
        })
      }

      console.log(temp);
      setQuestionList(temp);
      setfilteredQuestionList(temp);
      setisLoader(false)
    })

    getAllSubjectTags(setSubjectTags);
    getAllTopicTags(setTopicTags);
  }, [])

  useEffect(() => {
    if(questionList.length === 0)return;
    searchQuestion();
  }, [subjectTag , topicTag ,searchText])
  
  

  return (
    <div>
      {isLoader && <Loader text='Uploading ...'/>}
      <div className='text-center text-lg font-extrabold'>Questions List</div>
      <div className='space-y-5 mt-10 px-5'>
        <input type="text" className='border border-gray-200 rounded-lg px-2 py-1 w-full' placeholder='Search Questions...' value={searchText} onChange={onChange}/>
      </div>
      <div className='flex justify-start items-center px-5 mt-5 flex-wrap'>
          <Dropdown className="mr-5" list={subjectTags} selected={subjectTag} setSelected={setSubjectTag} title='Select Subject Tag'/>
          <Dropdown className="mr-5" list={topicTags} selected={topicTag} setSelected={setTopicTag} title='Select Topic Tag'/>
          <div className='mt-2 cursor-pointer' onClick={()=>{
            setSearchText("");
            setSubjectTag('Select Subject Tag');
            setTopicTag("Select Topic Tag");
          }}>Clear Filter &#10005;</div>
      </div>
      <div className='space-y-5 mt-10 px-5'>
        {
          filteredQuestionList.map((item,id)=>{
            return <QuestionCard key={id} item={item} id={id} selectedId={selectedId} setSelectedId={setSelectedId} fromCreateQuiz={fromCreateQuiz} quizQuestiuons = {quizQuestiuons} setQuizQuestiuons = {setQuizQuestiuons}/>
          })
        }
      </div>
    </div>
  )
}

export default QuestionsList;